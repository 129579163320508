import React, { useState, useEffect } from "react"
import VideoSection from "./VideoSection"
import InfiniteScroll from "react-infinite-scroll-component";
import { isInFuture } from "../../utils/dates"

const VideoList = ({ selectedFilter, videos }) => {
  const [subEnd, setSubEnd] = useState(3)

  useEffect(() => {
    setSubEnd(3)
  }, [selectedFilter])

  let results = videos.filter((video) => {
    return !isInFuture(video.publish_date, video.publish_time) &&
      (!video.filters ||
        selectedFilter === 'All' ||
        (video.filters && video.filters.find(f => f.label === selectedFilter)))
  })

  return (
    <InfiniteScroll
      dataLength={results.slice(0, subEnd).length}
      next={() => {
        setSubEnd(subEnd + 1)
      }}
      hasMore={true}
    >
      {results.slice(0, subEnd).map((video, ind) => (
        <VideoSection key={video.id} video={video} last={ind === results.length - 1} />
      ))}
    </InfiniteScroll>
  )
}

export default VideoList
