import React from "react"
import DateDisplay from "../DateDisplay"
import Text from "../Text"

const VideoInfo = ({ publishDate, description }) => (
  <div className="pt-3">
    <DateDisplay dateStr={publishDate} className="mb-1" />
    <Text.Normal>{description}</Text.Normal>
  </div>
)

export default VideoInfo
