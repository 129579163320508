import React from "react"
import YouTubeVideo from "../YouTubeVideo"
import VideoInfo from "./VideoInfo"
import './styles.scss'

const VideoSection = ({ video, last }) => {
  if (video && video.video_url) {
    return (
      <div className={`video-section ${last ? 'mb-0' : ''}`}>
        <YouTubeVideo watchURL={video.video_url} title={video.title} />
        <VideoInfo
          publishDate={video.publish_date}
          description={video.description}
        />
      </div>
    )
  }
  return null
}

export default VideoSection
